/** @jsx jsx */
import { jsx, Button, Flex } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedButton = animated(Button)
const AnimatedFlex = animated(Flex)

export const ButtonAnimatedIcon = ({ children, width = '50px', bg = 'none', scaleActive = 1.2, scaleInactive = 1, cursor = 'pointer', ariaLabel = 'Navigation button' }) => {
  const [active, setActive] = useState(false)
  const props = useSpring({
    transform: active ? `scale(${scaleActive})` : `scale(${scaleInactive})`,
  })
  return (
    <AnimatedButton
      variant="icon"
      m={0}
      p={0}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: props.transform,
        background: bg,
        minWidth: width,
        cursor: cursor,
      }}
      aria-label={ariaLabel}
    >
      <AnimatedFlex
        mx={0}
        style={{
          transform: props.transform,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </AnimatedFlex>
    </AnimatedButton>
  )
}
