/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from '../components/animation'
import Layout from '../components/layout'
import GramercyType from '../assets/gramercyType'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const PageNotFound = () => {
  const context = useThemeUI()
  const { theme } = context

  const themeIconColorFrom = theme.colors.iconFrom
  const themeIconColor = theme.colors.iconSecondary

  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })
  const buttonFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 450,
  })
  return (
    <Layout>
      <Flex
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AnimatedBox
          style={headingFade}
          sx={{
            textAlign: 'center',
            mt: [4, 5, 6],
          }}
        >
          <Styled.h2 sx={{ variant: 'text.heading' }}>Page Not Found</Styled.h2>
        </AnimatedBox>
        <AnimatedBox
          style={subtitleFade}
          sx={{
            px: [4, 5, 6],
            textAlign: 'center',
          }}
        >
          <Styled.h4 sx={{ variant: 'text.subheading' }}>Come here often? We hope not!</Styled.h4>
        </AnimatedBox>

        <AnimatedFlex style={buttonFade} sx={{ variant: 'layout.buttonContainerDefault' }}>
          <Box sx={{ m: 3 }}>
            <ButtonAnimated m={0} variant="primary" scaleActive="1.1" label="Head on home" linkTo="/" />
          </Box>
        </AnimatedFlex>

        <Flex sx={{ width: '100%', contain: 'content', justifyContent: 'center', alignItems: 'center', py: '4', px: '2', flexDirection: 'row' }}>
          <div sx={{ px: [1, 3, 4, 4], width: ['60%', '40%', '40%', '40%'] }}>
            <GramercyType width="100%" height="100%" fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
          </div>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default PageNotFound
