/** @jsx jsx */
import { jsx, Button, Text, Flex } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { navigate } from 'gatsby'

const AnimatedButton = animated(Button)
const AnimatedText = animated(Text)

export const ButtonAnimated = ({ label = 'Button', variant, scaleActive = 1.2, scaleInactive = 1, margin = 3, cursor = 'pointer', onClick, linkTo, actionType = 'default' }) => {
  const [active, setActive] = useState(false)

  const handleOnClick = () => {
    navigate(linkTo)
  }

  const props = useSpring({
    transform: active ? `scale(${scaleActive})` : `scale(${scaleInactive})`,
  })
  return (
    <Flex sx={{ width: '100%' }}>
      <div sx={{ width: '90%' }}>
        <AnimatedButton
          aria-label={`Link to ${linkTo}`}
          onClick={actionType === 'scroll' ? onClick : handleOnClick}
          m={margin}
          variant={variant}
          onMouseOver={() => setActive(true)}
          onMouseOut={() => setActive(false)}
          style={{
            transform: props.transform,
            cursor: cursor,
          }}
        >
          <AnimatedText
            style={{
              transform: props.transform,
            }}
          >
            {label}
          </AnimatedText>
        </AnimatedButton>
      </div>
    </Flex>
  )
}
