/** @jsx jsx */
import { jsx, Button } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedThemeButton = animated(Button)

export const ButtonAnimatedSpringy = ({
  mass = 3,
  tension = 180,
  friction = 12,
  clamp = false,
  children,
}) => {
  const [active, setActive] = useState(false)
  const props = useSpring({
    transform: active ? 'scale(1.2)' : 'scale(1)',
    config: active
      ? { mass: mass, tension: tension, friction: friction, clamp: clamp }
      : { mass: mass, tension: tension, friction: friction, clamp: clamp },
  })
  return (
    <AnimatedThemeButton
      m={3}
      variant="primary"
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: props.transform,
      }}
    >
      {children}
    </AnimatedThemeButton>
  )
}
