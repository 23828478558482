/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedThemeBox = animated(Box)

export const BoxAnimatedFloat = ({
  // color = 'white',
  // background = 'transparent',
  scaleActive = 1.2,
  scaleInactive = 1,
  children,
}) => {
  const [float, setFloat] = useState(false)
  const props = useSpring({
    // from: { transform: 'translate(0px, 0px)' },
    // to: { transform: 'translate(0px, 20px)' },
    from: { transform: 'translate(0px, 0px) rotate(-20deg) skew(20deg,20deg)' },
    to: { transform: 'translate(0px, 20px) rotate(20deg) skew(-20deg,-20deg)' },
    // from: { transform: 'translate3d(20px, -20px, 60px) rotate(-40deg)' },
    // to: { transform: 'translate3d(-20px, 20px, 60px) rotate(40deg)' },
    config: { mass: 6, tension: 80, friction: 60 },
    // config: { duration: 4500 },
    reverse: float,
    onRest: () => setFloat((state) => !state),
    reset: float,
  })
  return (
    <AnimatedThemeBox
      m={3}
      p={4}
      sx={{ border: '1px solid yellow' }}
      // color={color}
      // bg={background}
      // onMouseOver={() => setActive(true)}
      // onMouseOut={() => setActive(false)}
      style={props}
    >
      {children}
    </AnimatedThemeBox>
  )
}
