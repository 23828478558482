/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { animated, useSpring } from 'react-spring'

const AnimatedBox = animated(Box)

export const BoxAnimatedFade = ({ on = false, delay = 200, children }) => {
  const props = useSpring({
    opacity: on ? 1 : 0,
    delay: delay,
  })
  return (
    <AnimatedBox
      style={props}
      sx={{
        textAlign: 'center',
        mt: [4, 5, 6],
      }}
    >
      {children}
    </AnimatedBox>
  )
}
