/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useRef } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedThemeBox = animated(Box)

export const BoxAnimatedWobbly = ({
  color = 'white',
  background = 'primary',
  rotation = 10,
  scale = 1.5,
  children,
}) => {
  const interpolate = (value, start, end, limit) =>
    start + (value / limit) * (end - start)

  const calc = (
    rotation,
    scale,
    mouseX,
    mouseY,
    { offsetTop, offsetLeft, offsetWidth, offsetHeight },
  ) => [
    interpolate(
      mouseY - offsetTop + window.pageYOffset,
      rotation,
      -rotation,
      offsetWidth,
    ),
    interpolate(
      mouseX - offsetLeft + window.pageXOffset,
      -rotation,
      rotation,
      offsetHeight,
    ),
    scale,
  ]

  const transform = (x, y, s) =>
    `perspective(800px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const buttonRef = useRef()
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 7, tension: 500, friction: 40 },
  }))
  return (
    <AnimatedThemeBox
      m={3}
      p={4}
      color={color}
      bg={background}
      ref={buttonRef}
      onMouseMove={({ clientX, clientY }) =>
        set({ xys: calc(rotation, scale, clientX, clientY, buttonRef.current) })
      }
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        transform: props.xys.interpolate(transform),
      }}
    >
      {children}
    </AnimatedThemeBox>
  )
}
