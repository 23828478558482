/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedThemeBox = animated(Box)

export const BoxAnimated = ({
  color = 'white',
  background = 'primary',
  scaleActive = 1.2,
  scaleInactive = 1,
  children,
}) => {
  const [active, setActive] = useState(false)
  const props = useSpring({
    transform: active ? `scale(${scaleActive})` : `scale(${scaleInactive})`,
  })
  return (
    <AnimatedThemeBox
      m={3}
      p={4}
      color={color}
      bg={background}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: props.transform,
      }}
    >
      {children}
    </AnimatedThemeBox>
  )
}
