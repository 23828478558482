/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { animated, useSpring, config } from 'react-spring'

// If using with Waypoint, the onEnter will need to be set as follows inside the component that is using this animated slide
// {
//   /* <Waypoint
//   onEnter={() => {
//     if (!isToggled) toggle(true)
//   }}
// /> */
// }

const AnimatedBox = animated(Box)

export const BoxAnimatedSlide = ({ on = false, fromOpacity = 0, toOpacity = 1, delay = 200, children }) => {
  const props = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(200%,0,0)',
    config: config.molasses,
    // delay: 200,
  })
  return (
    <AnimatedBox
      style={props}
      sx={{
        px: [4, 5, 6],
        textAlign: 'center',
      }}
    >
      {children}
    </AnimatedBox>
  )
}
