/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedThemeBox = animated(Box)

export const BoxAnimatedSpringy = ({
  color = 'white',
  background = 'primary',
  mass = 3,
  tension = 180,
  friction = 12,
  clamp = false,
  children,
}) => {
  const [active, setActive] = useState(false)
  const props = useSpring({
    transform: active ? 'scale(1.2)' : 'scale(1)',
    config: active
      ? { mass: mass, tension: tension, friction: friction, clamp: clamp }
      : { mass: mass, tension: tension, friction: friction, clamp: clamp },
  })
  return (
    <AnimatedThemeBox
      m={3}
      p={4}
      color={color}
      bg={background}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: props.transform,
      }}
    >
      {children}
    </AnimatedThemeBox>
  )
}
